import React from "react";
import styles from "./Heading.module.css";

const Heading = () => {
  return (
    <div className={styles.header}>
      <div>
        <h3 className={styles.heading}> History</h3>
        <p className={styles.text}>
          {/* List of your Support plan that you have invested. */}
        </p>
      </div>
      {/* <button
        onClick={() => {
          window.location.href = "/plans"
        }}
        className={[styles.button, styles.deposit].join(" ")}>
        New Support Plan
      </button> */}
    </div>
  );
};

export const TxHeading = () => {
  return (
    <div className={styles.header}>
      <div>
        <h3 className={styles.heading}> Transactions</h3>
        <p className={styles.text}>
          {/* List of your Support plan that you have invested. */}
        </p>
      </div>
      {/* <button
        onClick={() => {
          window.location.href = "/plans"
        }}
        className={[styles.button, styles.deposit].join(" ")}>
        New Support Plan
      </button> */}
    </div>
  );
};
export default Heading;
