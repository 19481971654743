import React from "react";
import { FaPinterestP, FaFacebook, FaInstagram } from "react-icons/fa";
import styles from "./HeroSection.module.css";

const HeroSection = () => {
  const socials = [
    { icon: <FaPinterestP className={styles.icon} />, to: "#" },
    { icon: <FaFacebook className={styles.icon} />, to: "#" },
    { icon: <FaInstagram className={styles.icon} />, to: "#" },
  ];
  return (
    <section className={styles.wrapper}>
      <div className={["homeWrapper", styles.container].join(" ")}>
        <div className={styles.textContainer}>
          <h4 className={styles.heading}>Welcome to {process.env.REACT_APP_APP_NAME}</h4>

          <h2 className={styles.title}>Participate in the TECH REVOLUTION</h2>
          <p className={styles.text}>
          THE BLOCKCHAIN HUB is endorsed by top techies across the Globe that clearly expresses the fact that we stand as one of the top 3 BCT innovation solution providers across the Globe through our products and services in almost all possible domains in this BLOCKCHAIN ERA.
          </p>
          <button 
          onClick={() => {
            window.location.href = "/signup";
          }}
          className={styles.button}>Sign Up to Participate</button>
        </div>
        <div className={styles.socialContainer}>
          {/* {socials.map((el, i) => (
            <a href={el.to} target="_blank" rel="noreferrer" key={i}>
              {el.icon}
            </a>
          ))} */}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
