import React from "react";
import styles from "./Facilities.module.css";
import { clock, time, user } from "../../../images/image";

const Facilities = () => {
  const facilities = [
    {
      icon: time,
      title: "On Time Service",
      text: "We have been rated with remarkable comments and ranked always high only because of our on time service delivery to all our clients on the one hand and on the other hand because of the kind of innovation practices we introduce to the world that attract other entities in the industry to follow.",
    },
    {
      icon: user,
      title: "Our Team of Professionals",
      text: "We are a team of highly certified and qualified professionals actively involving in learning the industry centered ideas. We also pass on our learnings to the whole industry enthusiasts through the right kind of training and developmental programs that's resulting in our remarkable expansion in all possible terms.",
    },
    {
      icon: clock,
      title: "Our 24/7/365 Online Presence",
      text: "We work round the clock with dedicated industry enthusiasts functioning in all domains of the Blockchain industry in order to ensure comfort, safety, security and ease to our clientele. Our learning and earning community are also feeling respected, safe and comfortable because of this workstyle of ours.",
    },
  ];
  return (
    <div className={styles.wrapper}>
      <div className={["homeWrapper", styles.cardWrapper].join(" ")}>
        {facilities.map((el, i) => (
          <div className={styles.card} key={i}>
            <div className={styles.iconContainer}>
              <img src={el.icon} alt="#" className={styles.icon} />
            </div>
            <h4 className={styles.title}>{el.title}</h4>
            <p className={styles.text}>{el.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Facilities;
