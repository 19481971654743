import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import styles from "./Chart.module.css";

const Chart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const investmentData = [
       280,
    ];
    const profitData = [
      100,
    ];

    const options = {
      legend: {
        labels: {
          colors: "#A1A1A1",
        },
        position: "top",
        horizontalAlign: "left",
        offsetY: 10,
        offsetX: -10,
        markers: {
          radius: 0,
        },
      },
      series: [
        // {
        //   name: "Support",
        //   data: investmentData,
        //   type: "area",
        //   stroke: {
        //     curve: "smooth",
        //     colors: ["#816BFF"],
        //     width: 1,
        //   },
        //   fill: {
        //     colors: ["#816BFF"],
        //     opacity: 0.01,
        //   },
        // },
        // {
        //   name: "Profit",
        //   data: profitData,
        //   type: "area",
        //   stroke: {
        //     curve: "smooth",
        //     colors: ["#BD68FF"],
        //     width: 1,
        //   },
        //   fill: {
        //     colors: ["#BD68FF"],
        //     opacity: 0.01,
        //   },
        // },
      ],
      chart: {
        fontFamily: "Fira Code",
        fontSize: "14px",
        height: "150px",
        width: "100%",
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#816BFF", "#BD68FF"],
      xaxis: {
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      grid: {
        row: {
          show: false,
        },
        borderColor: "transparent",
        showBottomBorder: false,
      },
    };

    const chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, []);

  return <div id="chart" className={styles.chart} ref={chartRef}></div>;
};

export default Chart;
