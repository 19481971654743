import React, { useState } from "react";
import { IoPlayOutline } from "react-icons/io5";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./Services.module.css";
import { service } from "../../../images/image";

const Services = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderData = [
    {
      img: "https://images.unsplash.com/photo-1655393001768-d946c97d6fd1?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGFpfGVufDB8fDB8fHww",
      title: "AI and AR",
      info: "",
    },
    {
      img: "https://images.unsplash.com/photo-1556740714-a8395b3bf30f?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8ZmludGVjaHxlbnwwfHwwfHx8MA%3D%3D",
      title: "FINTECH",
      info: "",
    },
    {
      img: "https://images.unsplash.com/photo-1620634415912-ec5aa1e89d0d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGlvdHxlbnwwfHwwfHx8MA%3D%3D",
      title: "IoT",
      info: "",
    },
    // {
    //   img: service,
    //   title: "Capital Markets",
    //   info: "Welcome to Qudaix, a cutting-edge investment Solutionforefront of AI and blockchain innovation.  ",
    // },
    // {
    //   img: service,
    //   title: "Capital Markets",
    //   info: "Welcome to Qudaix, a cutting-edge investment Solutionforefront of AI and blockchain innovation.  ",
    // },
    // {
    //   img: service,
    //   title: "Capital Markets",
    //   info: "Welcome to Qudaix, a cutting-edge investment Solutionforefront of AI and blockchain innovation.  ",
    // },
    // {
    //   img: service,
    //   title: "Capital Markets",
    //   info: "Welcome to Qudaix, a cutting-edge investment Solutionforefront of AI and blockchain innovation.  ",
    // },
    // {
    //   img: service,
    //   title: "Capital Markets",
    //   info: "Welcome to Qudaix, a cutting-edge investment Solutionforefront of AI and blockchain innovation.  ",
    // },
    // {
    //   img: service,
    //   title: "Capital Markets",
    //   info: "Welcome to Qudaix, a cutting-edge investment Solutionforefront of AI and blockchain innovation.  ",
    // },
    // Add more objects as needed
  ];
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    arrows: true,
    slidesToScroll: 3,
    prevArrow: <CustomPrevArrow isActive={!currentSlide} />,
    nextArrow: (
      <CustomNextArrow isActive={currentSlide === sliderData.length - 1} />
    ),
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  return (
    <div className={styles.wrapper} id="productAndServices">
      <div className={["homeWrapper", styles.container].join(" ")}>
        <div className={styles.textContainer}>
          <h2 className={styles.heading}>Our Products And Services</h2>
          {/* <p className={styles.subHeading}>Covered in these areas</p> */}
          <p className={styles.line}></p>
        </div>

        <div className={styles.slider}>
          <Slider {...settings}>
            {sliderData.map((el, i) => (
              <div key={i} className={`${styles.swiperBox} `}>
                <div className={`${styles.card} `}>
                  <img src={el.img} alt="#" className={styles.image} />
                  <div className={styles.details}>
                    <h5 className={styles.title}>{el.title}</h5>
                    <p className={styles.info}>
                      {el.info} <span className={styles.seeMore}> </span>
                    </p>
                  </div>
                </div>{" "}
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Services;
const CustomPrevArrow = ({ isActive, onClick }) => {
  // Custom previous arrow component
  return (
    <div
      className={`${styles.previousButton} ${
        isActive ? styles.activeButton : ""
      }`}
      onClick={onClick}
    >
      <IoPlayOutline className={styles.arrow} />
    </div>
  );
};

const CustomNextArrow = ({ isActive, onClick }) => {
  // Custom next arrow component
  return (
    <div
      onClick={onClick}
      className={`${styles.nextButton} ${isActive ? styles.activeButton : ""}`}
    >
      <IoPlayOutline className={styles.arrow} />{" "}
    </div>
  );
};
