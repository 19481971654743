import React from "react";
import styles from "./AboutUs.module.css";
import { aboutUs } from "../../../images/image";

const AboutUs = () => {
  return (
    <div className={styles.wrapper} id="aboutUs">
      <div className={[styles.container, "homeWrapper"].join(" ")}>
        <div className={styles.mobileHeader}>
          {/* <p className={styles.tagline}>Our Little Story</p> */}
          <h3 className={styles.title}>About Us</h3>
          <p className={styles.line}></p>
        </div>
        <img src={aboutUs} alt="#" className={styles.image} />
        <div className={styles.textContainer}>
          <div className={styles.header}>
            {/* <p className={styles.tagline}>Our Little Story</p> */}
            <h3 className={styles.title}>About Us</h3>
            <p className={styles.line}></p>
          </div>

          <p className={styles.info}>
            We are a community who stand first in the first and front row with
            right possible solutions in the right speed and direction in this
            technological era where techies with the best possible products and
            solutions are highly honored and rewarded. We partner with
            innovative, fast, safe, secure and meaningful product and service
            providers in the era of Blockchain technology to see that we are
            always rated at one of the top 3 tech solution providers across the
            Globe.
          </p>
          {/* <button className={styles.button}>Learn More</button> */}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
