import React from "react";
import { ImClock } from "react-icons/im";

import { BsFillTelephoneFill, BsMailbox } from "react-icons/bs";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

import { home1Logo } from "../../../images/image";
import styles from "./Header.module.css";
import { useState } from "react";
import { Link } from "react-router-dom";

const Header = ({ dropDown, setDropDown }) => {
  const [active, setActive] = useState(0);

  const menuItems = [
    { item: "Home", to: "/" },
    { item: "Login", to: "/login" },
    { item: "Signup", to: "/signup" },
    // { item: "Menu4", to: "/home1" },
  ];

  const handleDropdown = () => {
    if (dropDown) {
      setDropDown(false);
    } else {
      setDropDown(true);
    }
  };
  return (
    <div className={styles.wrapper}>
      <div className={["homeWrapper", styles.container].join(" ")}>
        <img width="170" src={process.env.REACT_APP_LOGO} alt="#" className={styles.logo} />
        <div className={styles.infoBox}>
          {/* <p className={styles.info}>
            <ImClock className={styles.icon} />
            <span className={styles.text}>Mon to Fri: 10:00 to 18:00 (UTC)</span>
          </p>
          <p className={styles.info}>
            <BsMailbox className={styles.icon} />
            <span className={styles.text}> support@theblockchainhub.ai</span>
          </p> */}
        </div>
        <div className={styles.menuContainer} onClick={handleDropdown}>
          <p className={styles.menu}>{menuItems[active].item}</p>
          {dropDown ? (
            <IoIosArrowUp className={styles.arrow} />
          ) : (
            <IoIosArrowDown className={styles.arrow} />
          )}
          {dropDown && (
            <div className={styles.dropDowns}>
              {menuItems.map((el, i) => (
                <Link
                  to={el.to}
                  className={styles.menuItem}
                  key={i}
                  onClick={() => {
                    setActive(i);
                    handleDropdown();
                  }}
                >
                  {el.item}
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
