import React from "react";
import { FaArrowUp } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";

import styles from "./styles.module.css";

const ScrollToTop = () => {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div
      className={[styles.wrapper, styles.top].join(" ")}
      onClick={scrollToTop}
    >
      <FaArrowUp className={styles.arrow} />
    </div>
  );
};

export default ScrollToTop;
