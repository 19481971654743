import React from "react";
import { FaArrowDown } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import styles from "./styles.module.css";

const ScrollToBottom = () => {
  const scrollToBottom = () => {
    scroll.scrollToBottom();
  };
  return (
    <div
      className={[styles.wrapper, styles.bottom].join(" ")}
      onClick={scrollToBottom}
    >
      <FaArrowDown className={styles.arrow} />
    </div>
  );
};

export default ScrollToBottom;
